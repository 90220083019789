import React from 'react'
import"./Logs.css"
import MyLog from '../../Components/MyLog/MyLog'
const Logs = () => {

  return (
    <div className='container-fluid'>
        <h1>Street Things Devloyer.</h1>
        <h2>Logs</h2>
      <MyLog />
    </div>
  )
}

export default Logs