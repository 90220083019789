import axios from 'axios'
import React, { useState } from 'react'
import config from '../Constants/enviroment';
import { useNavigate } from 'react-router-dom';
const usePost = (endpoint,Body) => {
    const[isLoading,setIsLoading]=useState(null)
    const[err,setErr]=useState(null)
const[mes , setMes]=useState('')
    const navigate=useNavigate();
    const handleClick=()=>{
        setIsLoading("loading");
        axios.post(`${config.baseUrl}/${endpoint}`,Body)
        .then((res)=>{
            console.log(res)
          setMes("A Build is already running...")
            
        
        setTimeout(() => {
            navigate("/logs")
             
            }, 5000);

            setIsLoading(false)
        })
        .catch((err)=>{
            // setErr(err.response.data.message);
            setMes(err.response.data.message);
            setTimeout(() => {
            navigate("/logs")
             
            }, 5000);
            setIsLoading(true);
            
           
            
        });
    };
    return[isLoading,handleClick,mes];
};
 

export default usePost